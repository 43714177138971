<template>
    <div class="rounded-md p-3.5" :class="'alert-' + type">
        <div class="flex items-start">
            <div class="flex-shrink-0">
                <component
                    :is="iconComponent"
                    class="box-icon mt-px size-5"
                    aria-hidden="true"
                    :class="{
                        invisible: hideIcon,
                    }"
                />
            </div>
            <div class="ml-3">
                <span v-if="title" class="box-title inline-block font-semibold">
                    {{ title }}
                </span>
                <div class="box-copy" :class="{ 'mt-2': title }">
                    <p>
                        <slot>{{ msg }}</slot>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export const TYPES = ['note', 'info', 'danger', 'warning', 'success'];
</script>

<script setup>
import {
    AnnotationIcon,
    CheckCircleIcon,
    ExclamationIcon,
    InformationCircleIcon,
    XCircleIcon,
} from '@heroicons/vue/solid';
import { computed } from 'vue';

const props = defineProps({
    type: {
        type: String,
        required: false,
        default: () => 'info',
        validator(value) {
            const isValid = TYPES.includes(value);
            if (!isValid) {
                console.warn(`Allowed types are ${TYPES}`);
            }
            return isValid;
        },
    },
    title: {
        type: String,
        required: false,
        default: () => '',
    },
    msg: {
        type: String,
        required: false,
        default: () => '',
    },
    hideIcon: {
        type: Boolean,
        required: false,
        default: () => false,
    },
});

const iconComponent = computed(() => {
    switch (props.type) {
        case 'note':
            return AnnotationIcon;
        case 'notice':
        case 'warning':
            return ExclamationIcon;
        case 'success':
            return CheckCircleIcon;
        case 'danger':
            return XCircleIcon;
        default:
            return InformationCircleIcon;
    }
});
</script>

<style>
.alert-note {
    @apply bg-gray-100;
    .box-icon {
        @apply text-gray-700;
    }
    .box-title {
        @apply text-black;
    }
    .box-copy {
        @apply text-gray-700;
    }
}
.alert-info,
.alert-notice {
    @apply bg-blue-50;
    .box-icon {
        @apply text-blue-500;
    }
    .box-title {
        @apply text-blue-700;
    }
    .box-copy {
        @apply text-blue-700;
    }
}

.alert-success {
    @apply bg-green-50;
    .box-icon {
        @apply text-green-600;
    }

    .box-title {
        @apply text-green-700;
    }

    .box-copy {
        @apply text-green-700;
    }
}

.alert-warning {
    @apply bg-yellow-50;
    .box-icon {
        @apply text-yellow-500;
    }

    .box-title {
        @apply text-yellow-700;
    }

    .box-copy {
        @apply text-yellow-700;
    }
}

.alert-danger {
    @apply bg-red-50;
    .box-icon {
        @apply text-red-500;
    }

    .box-title {
        @apply text-red-700;
    }

    .box-copy {
        @apply text-red-700;
    }
}
.alert-info,
.alert-notice,
.alert-success,
.alert-warning,
.alert-danger {
    .box-copy {
        p {
            margin: 0;
        }
        a:hover {
            color: inherit;
        }
    }
}
</style>
