<template>
    <div
        class="flex w-full min-w-0 flex-col flex-wrap items-start at768:flex-row at768:items-center at768:space-x-2"
    >
        <h1
            class="text-xl font-semibold leading-6 text-black at480:text-2xl at768:text-2.5xl"
        >
            {{ product.name }}
        </h1>

        <reviews-badge
            v-if="product.ratingCount"
            :rating-score="product.ratingScore"
            :rating-count="product.ratingCount"
            class="mt-1 at768:mt-0"
        >
            <template #rating-count>
                <a
                    class="text-xs text-gray-500 underline underline-offset-2 hover:text-black"
                    href="#"
                    @click.prevent="
                        document
                            .getElementById('reviews-header')
                            ?.scrollIntoView({ behavior: 'smooth' })
                    "
                >
                    {{ product.ratingCount.toLocaleString() }}
                    {{ product.ratingCount > 1 ? 'reviews' : 'review' }}
                </a>
            </template>
        </reviews-badge>

        <div class="mt-2 space-x-2 at768:!ml-auto at768:mt-0">
            <copy-button
                v-if="showCopyButton"
                :text="document.location.href"
                button-label=""
                class="rounded border border-gray-300 px-3 py-1 text-sm font-medium hover:border-black focus:border-gray-300"
            ></copy-button>

            <wishlist-button></wishlist-button>
        </div>
    </div>
</template>

<script setup>
import CopyButton from 'components/Global/CopyButton.vue';
import ReviewsBadge from 'components/Global/ReviewsBadge.vue';
import WishlistButton from 'components/ListingDetail/WishlistButton.vue';
import { inject } from 'vue';

const product = inject('product');

const props = defineProps({
    showCopyButton: {
        type: Boolean,
        required: false,
        default: () => true,
    },
});
</script>
