<template>
    <div v-if="messages.length > 0" class="space-y-3">
        <alert-box
            v-for="(message, index) in messages"
            :key="index"
            :msg="message"
            type="danger"
        ></alert-box>
    </div>
</template>
<script setup>
import AlertBox from 'components/Global/AlertBox.vue';
import trim from 'lodash/trim';

const paramMap = {
    access_unavailable: 'Sorry, but this event is not currently available.',
};
const messages = [];
const params = new URLSearchParams(window.location.search);
params.forEach((value, key) => {
    if (paramMap[key]) {
        messages.push(paramMap[key]);
        params.delete(key);
    }
});
if (messages.length > 0) {
    const newUrl = trim(
        `${window.location.origin}${window.location.pathname}?${params.toString()}`,
        '?'
    );
    window.history.replaceState({}, '', newUrl);
}
</script>
