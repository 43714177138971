<template>
    <span v-if="ratingCount > 0" class="flex items-center space-x-1">
        <star-icon class="mt-px text-orange" :class="starSizeClass"></star-icon>

        <span class="font-semibold">
            <span class="sr-only">Average rating:</span>
            {{ ratingScoreFormatted }}
        </span>

        <slot name="rating-count">
            <span v-if="!hideRatingCount" class="text-gray">
                <span class="sr-only">Number of ratings:</span>({{
                    ratingCountFormatted
                }})
            </span>
        </slot>
    </span>
</template>

<script setup>
import { StarIcon } from '@heroicons/vue/solid';
import { computed } from 'vue';

const props = defineProps({
    ratingCount: {
        type: Number,
        required: false,
        default: () => 0,
    },
    ratingScore: {
        type: [Number, String],
        required: true,
    },
    hideRatingCount: {
        type: Boolean,
        required: false,
        default: () => false,
    },
    starSize: {
        type: Number,
        required: false,
        default: 5,
    },
});

const ratingScoreFormatted = computed(() =>
    typeof props.ratingScore === 'number' || props.ratingScore instanceof Number
        ? props.ratingScore.toFixed(1)
        : props.ratingScore
);
const ratingCountFormatted = computed(() => props.ratingCount.toLocaleString());

let starSizeClass;
switch (parseInt(props.starSize)) {
    case 3:
        starSizeClass = 'size-3';
        break;
    case 4:
        starSizeClass = 'size-4';
        break;
    case 6:
        starSizeClass = 'size-6';
        break;
    default:
        starSizeClass = 'size-5';
}
</script>
